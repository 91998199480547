import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { AnzicCategory } from 'src/../../Common/Model/taxonomy';

interface TaxonomyState {
  orgCategories: AnzicCategory[];
  orgCategoryNeedReload: boolean;
  serviceCategories: AnzicCategory[];
  serviceCategoryNeedReload: boolean;
  assetCategories: AnzicCategory[];
  assetCategoryNeedReload: boolean;
  chatCategories: AnzicCategory[];
  chatCategoryNeedReload: boolean;
}

const initialState: TaxonomyState = {
  orgCategories: [],
  orgCategoryNeedReload: true,
  serviceCategories: [],
  serviceCategoryNeedReload: true,
  assetCategories: [],
  assetCategoryNeedReload: true,
  chatCategories: [],
  chatCategoryNeedReload: true
};

const slice = createSlice({
  name: 'taxonomy',
  initialState,
  reducers: {
    clearState(state: TaxonomyState) {
      state.orgCategories.splice(0, state.orgCategories.length);
      state.serviceCategories.splice(0, state.serviceCategories.length);
      state.assetCategories.splice(0, state.assetCategories.length);
      state.chatCategories.splice(0, state.chatCategories.length);
      state.orgCategoryNeedReload = true;
      state.serviceCategoryNeedReload = true;
      state.assetCategoryNeedReload = true;
      state.chatCategoryNeedReload = true;
    },
    setOrgCategoryNeedReload(state: TaxonomyState, action: PayloadAction<boolean>): void {
      state.orgCategoryNeedReload = action.payload;
    },
    setServiceCategoryNeedReload(state: TaxonomyState, action: PayloadAction<boolean>): void {
      state.serviceCategoryNeedReload = action.payload;
    },
    setAssetCategoryNeedReload(state: TaxonomyState, action: PayloadAction<boolean>): void {
      state.assetCategoryNeedReload = action.payload;
    },
    setChatCategoryNeedReload(state: TaxonomyState, action: PayloadAction<boolean>): void {
      state.chatCategoryNeedReload = action.payload;
    },
    setOrgCategories(state: TaxonomyState, action: PayloadAction<AnzicCategory[]>) {
      state.orgCategories = action.payload;
      state.orgCategoryNeedReload = false;
    },
    setServiceCategories(state: TaxonomyState, action: PayloadAction<AnzicCategory[]>) {
      state.serviceCategories = action.payload;
      state.serviceCategoryNeedReload = false;
    },
    setAssetCategories(state: TaxonomyState, action: PayloadAction<AnzicCategory[]>) {
      state.assetCategories = action.payload;
      state.assetCategoryNeedReload = false;
    },
    setChatCategories(state: TaxonomyState, action: PayloadAction<AnzicCategory[]>) {
      state.chatCategories = action.payload;
      state.chatCategoryNeedReload = false;
    },
    updateOrgCategory(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.orgCategories.findIndex((item) => item.id === newCategory.id);
      if (index >= 0) state.orgCategories.splice(index, 1, newCategory);
      else state.orgCategories.push(newCategory);
    },
    updateServiceCategory(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.serviceCategories.findIndex((item) => item.id === newCategory.id);
      if (index >= 0) state.serviceCategories.splice(index, 1, newCategory);
      else state.serviceCategories.push(newCategory);
    },
    updateAssetCategory(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.assetCategories.findIndex((item) => item.id === newCategory.id);
      if (index >= 0) state.assetCategories.splice(index, 1, newCategory);
      else state.assetCategories.push(newCategory);
    },
    updateChatCategory(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.chatCategories.findIndex((item) => item.id === newCategory.id);
      if (index >= 0) state.chatCategories.splice(index, 1, newCategory);
      else state.chatCategories.push(newCategory);
    },
    updateOrgCategoryByCode(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.orgCategories.findIndex((item) => `${item.code}` === `${newCategory.code}`);
      if (index >= 0) state.orgCategories.splice(index, 1, newCategory);
      else state.orgCategories.push(newCategory);
    },
    updateServiceCategoryByCode(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.serviceCategories.findIndex((item) => `${item.code}` === `${newCategory.code}`);
      if (index >= 0) state.serviceCategories.splice(index, 1, newCategory);
      else state.serviceCategories.push(newCategory);
    },
    updateAssetCategoryByCode(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.assetCategories.findIndex((item) => `${item.code}` === `${newCategory.code}`);
      if (index >= 0) state.assetCategories.splice(index, 1, newCategory);
      else state.assetCategories.push(newCategory);
    },
    updateChatCategoryByCode(state: TaxonomyState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const index = state.chatCategories.findIndex((item) => `${item.code}` === `${newCategory.code}`);
      if (index >= 0) state.chatCategories.splice(index, 1, newCategory);
      else state.chatCategories.push(newCategory);
    }
  }
});

export const { reducer } = slice;

export const clearTaxonomyState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setOrgCategoryNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setOrgCategoryNeedReload(needReload));
};

export const setServiceCategoryNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setServiceCategoryNeedReload(needReload));
};

export const setAssetCategoryNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAssetCategoryNeedReload(needReload));
};

export const setChatCategoryNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setChatCategoryNeedReload(needReload));
};

export const setOrgCategories = (categories: AnzicCategory[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setOrgCategories(categories));
};

export const setServiceCategories = (categories: AnzicCategory[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setServiceCategories(categories));
};

export const setAssetCategories = (categories: AnzicCategory[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAssetCategories(categories));
};

export const setChatCategories = (categories: AnzicCategory[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setChatCategories(categories));
};

export const updateOrgCategory = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateOrgCategory(category));
};

export const updateServiceCategory = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateServiceCategory(category));
};

export const updateAssetCategory = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateAssetCategory(category));
};

export const updateChatCategory = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateChatCategory(category));
};

export const updateOrgCategoryByCode = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateOrgCategoryByCode(category));
};

export const updateServiceCategoryByCode = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateServiceCategoryByCode(category));
};

export const updateAssetCategoryByCode = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateAssetCategoryByCode(category));
};

export const updateChatCategoryByCode = (category: AnzicCategory) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateChatCategoryByCode(category));
};

export default slice;
