import wait from './wait';
// import useAuth from 'src/hooks/useAuth';

let jobList: any[] = [];

export const clearJobs = (): void => {
  jobList.splice(0, jobList.length);
};

const intervalJobs = (interval: number, dispatch, initial: boolean = true, ...args): void => {
  if (initial === true) {
    if (jobList.includes(dispatch) === true) return;
    jobList.push(dispatch);
  }

  // const auth = useAuth();

  wait(interval)
    .then(() => {
      // if (!auth.isAuthenticated) {
      //   clearJobs();
      //   return;
      // }
      dispatch(...args)
        .then(() => intervalJobs(interval, dispatch, false, ...args))
        .catch(() => {
          jobList = jobList.filter((job) => job !== dispatch);
        });
    });
};

export default intervalJobs;
