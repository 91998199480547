import callHttpApi from './httpCall';
import { accsiomURIs } from 'src/config';

export const httpGetFreeCommunityToken = async () => {
  const res = await callHttpApi(
    accsiomURIs.channel_uri,
    'GET_FREE_COMMUNITY_TOKEN',
    {}
  );
  return res;
};

export const httpGetChannelById = async (sid: string, getMessage = false) => {
  const res = await callHttpApi(
    accsiomURIs.channel_uri,
    'GET_BY_ID',
    { sid, getMessage }
  );
  return res;
};

export const httpGetParticipantsById = async (sid: string) => {
  const res = await callHttpApi(
    accsiomURIs.channel_uri,
    'GET_PARTICIPANTS_BY_ID',
    { sid }
  );
  return res;
};

export const httpGetAssetById = async (id: string) => {
  const res = await callHttpApi(
    accsiomURIs.asset_uri,
    'GET_BY_ID',
    { id }
  );
  return res;
};

export const httpGetOtherAssetsOfSupplier = async (ownerId: string, curId: string, purpose: number) => {
  const res = await callHttpApi(
    accsiomURIs.asset_uri,
    'GET_OTHER_ASSETS',
    { ownerId, curId, purpose }
  );
  return res;
};

export const httpGetMicroserviceForRequest = async (id: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_MICROSERVICE_FOR_REQUEST',
    { id }
  );
  return res;
};

export const httpGetFileResourcesByItemId = async (itemId: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_FILERESOURCE',
    { itemId }
  );
  return res;
};

export const httpGetPurchaseRatingStatistics = async (itemId: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_PURCHASE_RATING_STATISTICS',
    { itemId }
  );
  return res;
};

export const httpGetRatingStatistics = async (itemId: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_RATING_STATISTICS',
    { itemId }
  );
  return res;
};

export const httpGetRatingStatisticsByStar = async (itemId: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_RATING_STATISTICS_BY_STAR',
    { itemId }
  );
  return res;
};

export const httpGetRatingsByItemId = async (itemId: string, offset: number, count: number) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_RATINGS',
    { itemId, offset, count }
  );
  return res;
};

export const httpGetAssetThumbnail = async (id: string) => {
  const res = await callHttpApi(
    accsiomURIs.asset_uri,
    'GET_THUMBNAIL',
    { id }
  );
  return res;
};

export const httpGetInvitationUrl = async (params: any) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'ENCRYPT_PARAMS',
    { params }
  );
  return res;
};

export const httpGetInvitationDetails = async (text: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'DECRYPT_PARAMS',
    { text }
  );
  return res;
};

export const httpCheckMemberStatus = async (email: string, orgId: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'CHECK_MEMBER_STATUS',
    { email, orgId }
  );
  return res;
};

export const httpCheckClientOrgStatus = async (email: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'CHECK_CLIENT_ORG_STATUS',
    { email }
  );
  return res;
};

export const httpGetOrgByDomainName = async (domain: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_ORG_INFO_FROM_DOMAIN',
    { domain }
  );
  return res;
};

export const httpGetOrgStructure = async (id: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_ORG_STRUCTURE',
    { id }
  );
  return res;
};

export const httpGetOrgBannerUrl = async (id: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_BANNER_URL',
    { id }
  );
  return res;
};

export const httpSearchChannelMessage = async (sid: string, query: string, offset: number, limit: number) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'SEARCH_MESSAGE',
    { sid, query, offset, limit }
  );
  return res;
};

export const httpSearchOrganizations = async (offset: number, query: string, categories: string[], address: string, hideOrgIds: string[]) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'SEARCH',
    { offset, query, categories: JSON.stringify(categories), address, orgIds: JSON.stringify(hideOrgIds) }
  );
  return res;
};

export const httpGetUsersMin = async (users: any[]) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_USERS_MIN',
    { users: JSON.stringify(users) }
  );
  return res;
};

export const httpFindAddress = async (query: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'SEARCH_ADDRESS',
    { query }
  );
  return res;
};

export const httpGetExchangeRate = async (currencies: string[]) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_EXCHANGE_RATE',
    { currencies: JSON.stringify(currencies) }
  );
  return res;
};

export const httpGetAssetsPublishedByOwner = async (ownerId: string) => {
  const res = await callHttpApi(
    accsiomURIs.asset_uri,
    'GET_PUBLISHED',
    { ownerId }
  );
  return res;
};

export const httpGetMicroservicesPublishedByOwner = async (ownerId: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_PUBLISHED',
    { ownerId }
  );
  return res;
};

export const httpGetPartnerAssets = async (ownerId: string) => {
  const res = await callHttpApi(
    accsiomURIs.asset_uri,
    'GET_PARTNER_ASSETS',
    { ownerId }
  );
  return res;
};

export const httpGetPartnerMicroservices = async (ownerId: string) => {
  const res = await callHttpApi(
    accsiomURIs.microservice_uri,
    'GET_PARTNER_MICROSERVICES',
    { ownerId }
  );
  return res;
};

export const httpJoinAsClient = async (userInfo: any, domain: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'JOIN_AS_CLIENT',
    { userInfo, domain }
  );
  return res;
};

export const httpJoinAsClientOrg = async (orgInfo: any, domain: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'JOIN_AS_CLIENT_ORG',
    { orgInfo, domain }
  );
  return res;
};

export const httpGetOrganizationInfo = async (orgId: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_ORG_INFO',
    { orgId }
  );
  return res;
};

export const httpGetUserInfo = async (uid: string) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_USER_INFO',
    { uid }
  );
  return res;
};

export const httpGetAnzicTaxonomyInfoByType = async (type: number) => {
  const res = await callHttpApi(
    accsiomURIs.orgaboutus_uri,
    'GET_ANZIC_BY_TYPE',
    { type }
  );
  return res;
};

export const ebsGetMicroservicesPublished = async (ownerIds: string[], offset: number, oids: string[], query: string, categories: string[], activeOrgId) => {
  const res = await callHttpApi(
    'https://wsserver.accziom.com/mssearch',
    'SEARCH',
    { ownerIds: ownerIds.join(','), offset, oids: oids.join(','), query, categories: categories.join(','), activeOrgId }
  );
  return res;
};

export const ebsGetAssetsPublished = async (ownerIds: string[], offset: number, oids: string[], query: string, categories: string[], activeOrgId) => {
  const res = await callHttpApi(
    'https://wsserver.accziom.com/assetsearch',
    'SEARCH',
    { ownerIds: ownerIds.join(','), offset, oids: oids.join(','), query, categories: categories.join(','), activeOrgId }
  );
  return res;
};

export const ebsGetChannels = async (info: any, offset: number, query: string, categories: any) => {
  const res = await callHttpApi(
    'https://wsserver.accziom.com/channelsearch',
    'SEARCH',
    { info: JSON.stringify(info), offset, query, categories: JSON.stringify(categories) }
  );
  return res;
};

export const httpGetAttachmentFileDownloadUrl = async (uid: string) => {
  const res = await callHttpApi(
    accsiomURIs.channel_uri,
    'GET_URL',
    { uid }
  );
  return res;
};
