import getUserDisplayName from './getUserDisplayName';

const getInitials = (name = ''): string => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

const colors = ['#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB', '#B3E5FC', '#B2DFDB'];

// const numberFromText = (text: string): number => {
//   const charCodes = text
//     .split('')
//     .map((char) => char.charCodeAt(0) - 65)
//     .join('');
//   return Math.trunc(parseInt(charCodes, 10));
// };

// export const getInitialBackground = (text: string): string => colors[numberFromText(text) % colors.length];
export const getInitialBackground = (initials: string): string => {
  let sumInitials = 0;
  for (let i = 0; i < initials.length; i++) {
    sumInitials += (initials.charCodeAt(i) - 65);
  }
  return colors[sumInitials % colors.length];
};

export const getInitialOfMiniUser = (miniUser): string => (getInitials(getUserDisplayName(miniUser)));

export default getInitials;
