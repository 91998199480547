const favIconMiddleware = (_store) => (next) => (action) => {
  const result = next(action);

  if (action.type.startsWith('apprising/') || action.type.startsWith('chat/')) {
    const state = _store.getState();
    const { thread } = state.chat;
    const icon = document.getElementById('favicon');
    const curHref = icon.getAttribute('href');
    if (thread?.unreadCount > 0) {
      icon.setAttribute('href', curHref.replace('favicon.svg', 'favicon_notification.svg'));
    } else {
      icon.setAttribute('href', curHref.replace('favicon_notification.svg', 'favicon.svg'));
    }
  }

  return result;
};

export default favIconMiddleware;
