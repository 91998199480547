import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Thread, Message, Contact } from 'src/types/chat';
// modified by Makarov --2021/11/01
import { ACCZIOM_NONE, COMMUNITY_CHAT_THREAD, getUserIdByType } from 'src/globals';
import { deepCopy } from 'src/utils/copyObject';
import { Channel, ChannelParticipant } from 'src/../../Common/Model/channel';
import { LoadingStatus } from './user';

const cmpParticipantFn = (a: ChannelParticipant, b: ChannelParticipant): number => {
  const at: number = new Date(a.createdAt).getTime();
  const bt: number = new Date(b.createdAt).getTime();
  return at - bt;
};

interface ChatApiStatus {
  option: string;
  loadingStatus: LoadingStatus;
}

const initialThread = {
  sid: '',
  title: '',
  lastMessage: '',
  lastMessageTime: -1,
  messages: [],
  isJoined: false,
  isTyping: false,
  typerId: '',
  lastReadMsgIndex: -1,
  lastMsgIndex: -1,
  createdBy: '',
  participants: [],
  type: COMMUNITY_CHAT_THREAD,
  unreadCount: 0,
} as Thread;

const initialChannel = {
  id: '',
  sid: '',
  title: '',
  description: '',
  subCategory: '',
  thumb: '',
  type: COMMUNITY_CHAT_THREAD,
  status: 0,
  creator: { id: '', type: ACCZIOM_NONE },
  createdAt: '',
  updatedAt: ''
} as Channel;

const initialApiStatus = {
  option: 'public',
  loadingStatus: LoadingStatus.NONE
} as ChatApiStatus;

interface ChatState {
  twilioStatus: ChatApiStatus;
  msgEditingItem: any;
  msgQuotingItem: number;
  thread: Thread;
  channel: Channel;
  channelParticipants: ChannelParticipant[];
  channelThumbUrl: string;
  lastMsgIds: Record<string, number>;
}

const initialState: ChatState = {
  twilioStatus: initialApiStatus,
  msgEditingItem: null,
  msgQuotingItem: null,
  thread: initialThread,
  channel: initialChannel,
  channelParticipants: [],
  channelThumbUrl: null,
  lastMsgIds: {}
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    cleanAll(state: ChatState): void {
      state.thread = initialThread;
      state.channel = initialChannel;
      state.msgEditingItem = null;
      state.msgQuotingItem = null;
      state.channelParticipants.splice(0, state.channelParticipants.length);
      state.channelThumbUrl = null;
      state.lastMsgIds = {};
    },
    cleanTwilioStatus(state: ChatState): void {
      state.twilioStatus = initialApiStatus;
    },
    setTwilioStatus(state: ChatState, action: PayloadAction<ChatApiStatus>): void {
      state.twilioStatus = action.payload;
    },
    setChannelInfo(state: ChatState, action: PayloadAction<{ channel: Channel; recipients: ChannelParticipant[]; thumbUrl: string; }>): void {
      const { channel, recipients, thumbUrl } = action.payload;
      state.channel = channel;
      state.channelParticipants = [...recipients];
      state.channelParticipants.sort(cmpParticipantFn);
      state.channelThumbUrl = thumbUrl;
    },
    updateChannel(state: ChatState, action: PayloadAction<any>): void {
      const updated = action.payload;
      Object.keys(updated).forEach((key) => {
        state.channel[key] = updated[key];
      });
    },
    setChannelParticipants(state: ChatState, action: PayloadAction<ChannelParticipant[]>): void {
      state.channelParticipants = [...action.payload];
      state.channelParticipants.sort(cmpParticipantFn);
    },
    setThread(state: ChatState, action: PayloadAction<Thread>): void {
      state.thread = action.payload;
    },
    threadJoined(state: ChatState): void {
      state.thread.isJoined = true;
    },
    setMessages(state: ChatState, action: PayloadAction<Message[]>): void {
      const msgs = action.payload;
      if (msgs.length < 1) return;
      const { messages } = state.thread;
      if (messages.length > 0 && messages[messages.length - 1].id < msgs[0].id) messages.push(...msgs);
      else messages.unshift(...msgs);
      if (messages[messages.length - 1].id === msgs[msgs.length - 1].id) {
        state.thread.lastMessage = JSON.stringify(msgs[msgs.length - 1].body);
        state.thread.lastMessageTime = msgs[msgs.length - 1].createdAt;
      }
    },
    setMsgEditingItem(state: ChatState, action: PayloadAction<any>): void {
      state.msgEditingItem = action.payload;
    },
    setMsgQuotingItem(state: ChatState, action: PayloadAction<any>): void {
      state.msgQuotingItem = action.payload;
    },
    setMsgUnreadCount(state: ChatState, action: PayloadAction<any>): void {
      state.thread.unreadCount = action.payload;
    },
    updateUserOnlineStatus(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      state.thread.participants.forEach((p) => {
        if (p.uid === body.uid) p.isOnline = body.isOnline;
      });
    },
    msgAdded(state: ChatState, action: PayloadAction<Message>): void {
      const msg = action.payload;
      state.thread.messages.push(msg);
      state.thread.lastMessage = JSON.stringify(msg.body);
      state.thread.lastMessageTime = msg.createdAt;
    },
    msgSent(state: ChatState, action: PayloadAction<Message>): void {
      const msg = action.payload;
      const { messages } = state.thread;
      for (let i = messages.length - 1; i >= 0; i--) {
        const item = messages[i];
        if (item.id < 0 && msg.body.id === item.body.id) {
          item.sent = true;
          item.id = msg.id;
          break;
        }
      }
    },
    addRecipientsToThread(state: ChatState, action: PayloadAction<any>): void {
      state.thread.participants.push(...action.payload);
    },
    setMessageThumb(state: ChatState, action: PayloadAction<any>): void {
      const { msg, uri, url } = action.payload;
      const { messages } = state.thread;
      const m = messages.find((item) => item.body.id === msg.body.id);
      const oldBody = deepCopy(m.body);
      m.body = {
        ...oldBody,
        files: oldBody.files.map((f) => ({
          ...f,
          ...(f.uri === uri && {
            thumbUri: url
          })
        }))
      };
    },
    msgUpdated(state: ChatState, action: PayloadAction<Message>): void {
      const msg = action.payload;
      const { messages } = state.thread;
      const m = messages.find((item) => item.id === msg.id);
      m.body = msg.body;
      m.attrib = msg.attrib;
      m.updated = true;
    },
    markThreadAsSeen(state: ChatState): void {
      state.thread.unreadCount = 0;
    },
    removeMessage(state: ChatState, action: PayloadAction<any>): void {
      const msgId = action.payload;
      const { messages } = state.thread;
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].id === msgId) {
          messages.splice(i, 1);
          break;
        }
      }
    },
    updateLastReadMsgIndex(state: ChatState, action: PayloadAction<any>): void {
      state.thread.lastReadMsgIndex = action.payload;
    },
    updateLastMsgIndex(state: ChatState, action: PayloadAction<any>): void {
      state.thread.lastMsgIndex = action.payload;
    },
    setTypingStarted(state: ChatState, action: PayloadAction<any>): void {
      const uid = action.payload;
      const participant = state.thread.participants.find((item) => item.uid === uid);
      if (participant) {
        state.thread.typerId = getUserIdByType(participant);
        state.thread.isTyping = true;
      }
    },
    setTypingEnded(state: ChatState): void {
      state.thread.isTyping = false;
    },
    removeParticipants(state: ChatState, action: PayloadAction<any>): void {
      const uids = action.payload;
      state.thread.participants = state.thread.participants.filter((participant) => !(uids.includes(participant.uid)));
    },
    updateLastReadMsgIds(state: ChatState, action: PayloadAction<Record<string, number>>): void {
      const uids = action.payload;
      Object.keys(uids).forEach((uid) => {
        state.lastMsgIds[uid] = uids[uid];
      });
    }
  }
});

export const { reducer } = slice;

export const cleanTwilioStatus = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.cleanTwilioStatus());
};

export const setTwilioStatus = (newStatus: ChatApiStatus): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setTwilioStatus(newStatus));
};

export const setChannelInfo = (channel: Channel, recipients: ChannelParticipant[], thumbUrl: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setChannelInfo({ channel, recipients, thumbUrl }));
};

export const updateChannel = (channel: any): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateChannel(channel));
};

export const setChannelParticipants = (recipients: ChannelParticipant[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setChannelParticipants(recipients));
};

export const setThread = (thread: Thread): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setThread(thread));
};

export const threadJoined = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.threadJoined());
};

export const msgAdded = (msg: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.msgAdded(msg));
};

export const msgSent = (msg: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.msgSent(msg));
};

export const msgUpdated = (msg: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.msgUpdated(msg));
};

export const setMessages = (messages: Message[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setMessages(messages));
};

export const setMsgUnreadCount = (num: number): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setMsgUnreadCount(num));
};

export const updateLastMsgIndex = (num: number): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateLastMsgIndex(num));
};
export const updateLastReadMsgIndex = (num: number): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateLastReadMsgIndex(num));
};
export const markThreadAsSeen = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.markThreadAsSeen());
};

export const setTypingStarted = (uid: string) => (dispatch): void => {
  dispatch(slice.actions.setTypingStarted(uid));
};

export const setTypingEnded = () => (dispatch): void => {
  dispatch(slice.actions.setTypingEnded());
};

export const updateUserOnlineStatus = (_uid: string, _isOnline: boolean) => (dispatch): void => {
  dispatch(slice.actions.updateUserOnlineStatus({ uid: _uid, isOnline: _isOnline }));
};

export const addRecipientsToThread = (recipients: Contact[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.addRecipientsToThread(recipients));
};

export const removeParticipants = (uids: string[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.removeParticipants(uids));
};

export const cleanUpChat = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.cleanAll());
};

export const setMessageThumb = (msg: Message, uri: string, url: string): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setMessageThumb({ msg, uri, url }));
};

export const removeMessage = (msgId: number): AppThunk => (dispatch): void => {
  dispatch(slice.actions.removeMessage(msgId));
};

export const setEditingMsg = (msgId: number): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setMsgEditingItem(msgId));
  setTimeout(() => {
    document.getElementById('messageInput')?.focus();
  }, 100);
};

export const setQuotingMsg = (msgId: number): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setMsgQuotingItem(msgId));
  setTimeout(() => {
    document.getElementById('messageInput')?.focus();
  }, 100);
};

export const updateLastReadMsgIds = (dat: Record<string, number>): AppThunk => (dispatch): void => {
  dispatch(slice.actions.updateLastReadMsgIds(dat));
};

export default slice;
