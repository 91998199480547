import Conversations, { User } from '@twilio/conversations';
import { lambdaGetTwilioToken, lambdaAddAndDeleteMessages, lambdaUpdateMessage, lambdaCreateCommunityAdminParticipants } from 'src/aws/lambdaDispatch';
import { fetchUrl } from 'src/utils/fileUpDownload';
import { Conversation } from '@twilio/conversations/lib/data/conversations';
import { Thread, Message as AccsiomMsg, Contact, MessageBody, MessageAttribue } from 'src/types/chat';
import { Message as TwilioMsg } from '@twilio/conversations/lib/message';
import {
  markThreadAsSeen,
  msgAdded,
  msgSent,
  msgUpdated,
  addRecipientsToThread,
  removeMessage,
  removeParticipants,
  setMessageThumb,
  setMsgUnreadCount,
  setTypingEnded,
  setTypingStarted,
  threadJoined,
  updateLastMsgIndex,
  updateLastReadMsgIndex,
  updateUserOnlineStatus,
  updateLastReadMsgIds,
  setThread,
  cleanUpChat,
  setMessages
} from 'src/slices/chat';
import { updateUserMinInfos } from 'src/slices/organization';
import { Participant } from '@twilio/conversations/lib/participant';
import { v4 as uuidv4 } from 'uuid';
// modified by Makarov --2021/11/01
import { PAGE_SIZE, getUserIdByType, ACCZIOM_CLIENT_ORG, ACCZIOM_SUPPLIER, FREE_USER_ID, ACCZIOM_ORG, getUserInfoByType, ACCZIOM_MEMBER } from 'src/globals';
import axios from 'axios';
import gstore from 'src/store';
import { accsiomURIs } from 'src/config';
import { getUserMinInfoByID } from 'src/utils/getActiveOrgInfo';
import { httpGetAttachmentFileDownloadUrl, httpGetFreeCommunityToken, httpGetUsersMin } from './httpDispatch';
import { addCallApiCount } from 'src/slices/user';

const TwilioToAccsiomMsg = (msg: TwilioMsg) : AccsiomMsg => ({
  id: msg.index,
  body: JSON.parse(msg.body),
  convId: msg.conversation.sid,
  attrib: msg.attributes,
  createdAt: msg.dateCreated.getTime(),
  senderId: msg.author,
  sent: true
} as AccsiomMsg);

const TwilioToAccsiomThread = (conv: Conversation) : Thread => {
  const attrib = conv.attributes as any;
  return {
    sid: conv.sid,
    title: conv.friendlyName,
    participants: attrib.recipients,
    lastMessage: attrib.lastMessage,
    lastMessageTime: attrib.lastMessageTime,
    type: attrib.type,
    createdBy: conv.createdBy,
    unreadCount: 0,
    isTyping: false,
    lastReadMsgIndex: conv.lastReadMessageIndex === null ? 0 : conv.lastReadMessageIndex,
    lastMsgIndex: (conv.lastMessage === null || conv.lastMessage === undefined) ? 0 : conv.lastMessage.index,
    messages: [],
    typerId: ''
  } as Thread;
};

class ChatApi {
  private client: Conversations = null;

  private uid: string = '';

  private token: string = '';

  private conv: Conversation = null;

  private users: Record<string, User> = {};

  private messages: TwilioMsg[] = [];

  private lastMsgId: number = -1;

  private dispatch = null;

  private msgId: number = -1;

  private isConnecting: boolean = false;

  public isActive: boolean = false;

  cleanUp(): void {
    if (!this.client) return;
    this.isActive = false;
    this.client.shutdown();
    this.dispatch = null;
    this.isConnecting = false;
    this.messages.splice(0, this.messages.length);
    this.lastMsgId = -1;
    this.conv = null;
    this.uid = '';
    this.users = {};
    this.token = '';
    this.msgId = -1;
    this.client = null;
  }

  initConnection(uid: string, _dispatch): Promise<boolean> {
    if (this.client) {
      if (this.client.connectionState === 'connected' || this.client.connectionState === 'connecting') {
        return Promise.resolve(true);
      }
    }

    if (this.isConnecting) return Promise.resolve(true);

    gstore.dispatch(addCallApiCount());

    this.isConnecting = true;

    return new Promise((resolve, reject) => {
      this.getToken(uid)
        .then((_token) => {
          this.token = _token;
          Conversations.create(this.token)
            .then((_conn) => {
              this.client = _conn;
              this.uid = uid;
              this.dispatch = _dispatch;
              this.initListeners();
              this.isActive = true;
              this.isConnecting = false;
              resolve(true);
            })
            .catch(() => {
              reject(new Error('Failed with twilio conversation initialization'));
            });
        })
        .catch(() => {
          reject(new Error('Failed with twilio token request'));
        });
    });
  }

  initFreeConnection(_dispatch): Promise<boolean> {
    if (this.client) {
      if (this.client.connectionState === 'connected' || this.client.connectionState === 'connecting') {
        return Promise.resolve(true);
      }
    }

    if (this.isConnecting) return Promise.resolve(true);

    gstore.dispatch(addCallApiCount());

    this.isConnecting = true;

    return new Promise((resolve, reject) => {
      this.getFreeToken()
        .then((_token) => {
          this.token = _token;
          Conversations.create(this.token)
            .then((_conn) => {
              this.client = _conn;
              this.uid = FREE_USER_ID;
              this.dispatch = _dispatch;
              this.initFreeListeners();
              this.isActive = true;
              this.isConnecting = false;
              resolve(true);
            })
            .catch(() => {
              reject(new Error('Failed with twilio conversation initialization'));
            });
        })
        .catch(() => {
          reject(new Error('Failed with twilio token request'));
        });
    });
  }

  getUid() : string {
    return this.uid;
  }

  loadImageThumbnail(uri: string, msg: AccsiomMsg): void {
    httpGetAttachmentFileDownloadUrl(`${uri}-thumb`).then((url) => {
      fetchUrl(url).then((localUrl) => {
        this.dispatch?.(setMessageThumb(msg, uri, localUrl));
      }).catch(() => {});
    });
  }

  loadVideoThumbnail(uri: string, msg: AccsiomMsg): void {
    httpGetAttachmentFileDownloadUrl(`${uri}.png`).then((url) => {
      fetchUrl(url).then((localUrl) => {
        this.dispatch?.(setMessageThumb(msg, uri, localUrl));
      }).catch(() => {});
    });
  }

  initListeners(): void {
    if (this.client) {
      this.client.on('connectionError', () => {
        this.initConnection(this.uid, this.dispatch);
      });

      this.client.on('tokenAboutToExpire', () => {
        lambdaGetTwilioToken(this.uid, true)
          .then((token) => {
            if (token !== null) {
              this.client.updateToken(token);
            } else {
              this.cleanUp();
            }
          })
          .catch(() => {
            console.log('updateToken Failed.');
          });
      });

      this.client.on('messageAdded', (msg: TwilioMsg) => {
        if (!this.conv) return;
        const aMsg = TwilioToAccsiomMsg(msg);

        if (this.conv.sid === aMsg.convId) {
          this.messages.push(msg);

          if (aMsg.senderId !== this.uid) {
            const { files } = aMsg.body;
            files.forEach(({ type, uri }) => {
              if (type !== undefined) {
                if (type.includes('image/')) {
                  this.loadImageThumbnail(uri, aMsg);
                }
                if (type.includes('video/mp4')) {
                  this.loadVideoThumbnail(uri, aMsg);
                }
              }
            });
            this.dispatch?.(msgAdded(aMsg));
          } else {
            this.dispatch?.(msgSent(aMsg));
          }

          this.dispatch?.(updateLastMsgIndex(aMsg.id));
          this.lastMsgId = aMsg.id;
          this.updateLastReadMsgIndex();

          if (this.uid === this.conv.createdBy) {
            const att: any = this.conv.attributes;
            att.lastMessage = aMsg.body;
            att.lastMessageTime = aMsg.createdAt;
            this.conv.updateAttributes(att);
          }
        }

        if (aMsg.senderId === this.uid) {
          lambdaAddAndDeleteMessages(aMsg.convId, [{
            msgId: aMsg.id,
            senderId: aMsg.senderId,
            textContent: aMsg.body.textContent,
            textType: aMsg.body.textType,
            files: aMsg.body.files
          }], []);
        }

        // if (aMsg && aMsg.convId) {
        //   if (document.hasFocus() === false && window.Notification !== null && Notification.permission === 'granted') this.notifyMessage(aMsg);
        // }
      });

      this.client.on('messageUpdated', (msg: any) => {
        if (!this.conv) return;
        const aMsg = TwilioToAccsiomMsg(msg.message);
        if (this.conv.sid === aMsg.convId) this.dispatch?.(msgUpdated(aMsg));
        if (aMsg.senderId === this.uid) {
          lambdaUpdateMessage({
            sid: aMsg.convId,
            msgId: aMsg.id,
            senderId: aMsg.senderId,
            textContent: aMsg.body.textContent,
            textType: aMsg.body.textType,
            files: aMsg.body.files
          });
        }
      });

      this.client.on('messageRemoved', (msg: TwilioMsg) => {
        if (!this.conv) return;
        if (this.conv.sid === msg.conversation.sid) this.dispatch?.(removeMessage(msg.index));
        if (msg.author === this.uid) lambdaAddAndDeleteMessages(msg.conversation.sid, [], [msg.index]);
      });

      this.client.on('typingStarted', (p: Participant) => {
        if (!this.conv) return;
        const { sid } = p.conversation;
        const { identity } = p;

        if (identity === this.uid) return;
        if (sid === this.conv.sid) this.dispatch?.(setTypingStarted(identity));
      });

      this.client.on('typingEnded', (p: Participant) => {
        if (!this.conv) return;
        const { sid } = p.conversation;
        if (sid === this.conv.sid) this.dispatch?.(setTypingEnded());
      });

      this.client.on('conversationJoined', (_conv: Conversation) => {
        if (!this.conv) return;
        const { sid } = _conv;
        if (sid === this.conv.sid) this.dispatch?.(threadJoined());
      });

      this.client.on('participantUpdated', ({ participant }) => {
        if (!this.conv) return;
        const { sid } = participant.conversation;
        if (sid === this.conv.sid) {
          const { identity, lastReadMessageIndex } = participant;
          const lastMsgIds = {};
          lastMsgIds[identity] = lastReadMessageIndex;
          this.dispatch?.(updateLastReadMsgIds(lastMsgIds));
        }
      });
    }
  }

  initFreeListeners(): void {
    if (this.client) {
      this.client.on('connectionError', () => {
        this.initFreeConnection(this.dispatch);
      });

      this.client.on('tokenAboutToExpire', () => {
        httpGetFreeCommunityToken()
          .then((token) => {
            if (token !== null) {
              this.client.updateToken(token);
            } else {
              this.cleanUp();
            }
          })
          .catch(() => {
            console.log('updateToken Failed.');
          });
      });

      this.client.on('messageAdded', (msg: TwilioMsg) => {
        if (!this.conv) return;
        const aMsg = TwilioToAccsiomMsg(msg);

        if (this.conv.sid === aMsg.convId) {
          this.messages.push(msg);

          const { files } = aMsg.body;
          files.forEach(({ type, uri }) => {
            if (type !== undefined) {
              if (type.includes('image/')) {
                this.loadImageThumbnail(uri, aMsg);
              }
              if (type.includes('video/mp4')) {
                this.loadVideoThumbnail(uri, aMsg);
              }
            }
          });
          this.dispatch?.(msgAdded(aMsg));

          this.dispatch?.(updateLastMsgIndex(aMsg.id));
          this.lastMsgId = aMsg.id;
          this.updateLastReadMsgIndex();
        }

        // if (aMsg && aMsg.convId) {
        //   if (document.hasFocus() === false && window.Notification !== null && Notification.permission === 'granted') this.notifyMessage(aMsg);
        // }
      });

      this.client.on('messageUpdated', (msg: any) => {
        if (!this.conv) return;
        const aMsg = TwilioToAccsiomMsg(msg.message);
        if (this.conv.sid === aMsg.convId) this.dispatch?.(msgUpdated(aMsg));
      });

      this.client.on('messageRemoved', (msg: TwilioMsg) => {
        if (!this.conv) return;
        if (this.conv.sid === msg.conversation.sid) this.dispatch?.(removeMessage(msg.index));
      });

      this.client.on('typingStarted', (p: Participant) => {
        if (!this.conv) return;
        const { sid } = p.conversation;
        const { identity } = p;
        if (sid === this.conv.sid) this.dispatch?.(setTypingStarted(identity));
      });

      this.client.on('typingEnded', (p: Participant) => {
        if (!this.conv) return;
        const { sid } = p.conversation;
        if (sid === this.conv.sid) this.dispatch?.(setTypingEnded());
      });

      this.client.on('conversationJoined', (_conv: Conversation) => {
        if (!this.conv) return;
        const { sid } = _conv;
        if (sid === this.conv.sid) this.dispatch?.(threadJoined());
      });

      this.client.on('participantUpdated', ({ participant }) => {
        if (!this.conv) return;
        const { sid } = participant.conversation;
        if (sid === this.conv.sid) {
          const { identity, lastReadMessageIndex } = participant;
          const lastMsgIds = {};
          lastMsgIds[identity] = lastReadMessageIndex;
          this.dispatch?.(updateLastReadMsgIds(lastMsgIds));
        }
      });
    }
  }

  loadAvatar(participants: Contact[], creatorId: string, dispatch: any): void {
    const newParticipants = participants.filter((participant) => !getUserMinInfoByID(getUserInfoByType(participant)));
    const newUsers = newParticipants.map((participant) => {
      if ([ACCZIOM_CLIENT_ORG, ACCZIOM_SUPPLIER].includes(participant.type)) return { id: getUserIdByType(participant), oid: creatorId, type: participant.type };
      return { id: getUserIdByType(participant), type: participant.type };
    });
    const nonIndividuals = participants.filter((participant) => [ACCZIOM_ORG, ACCZIOM_CLIENT_ORG, ACCZIOM_SUPPLIER].includes(participant.type)).map((participant) => ({ id: participant.uid, type: ACCZIOM_MEMBER }));
    newUsers.push(...nonIndividuals.filter((participant) => !getUserMinInfoByID(participant)));
    if (newUsers.length > 0) {
      httpGetUsersMin(newUsers)
        .then((miniUsers) => {
          dispatch(updateUserMinInfos(miniUsers));
        })
        .catch((err) => {
          console.log(`Failed to load avatar with ${JSON.stringify(err)}`);
        });
    }
  }

  setConvDispatch(_conv: Conversation): Promise<void> {
    if (!_conv) return Promise.reject(new Error('Conversation not exists.'));
    this.conv = _conv;
    const thread: Thread = TwilioToAccsiomThread(_conv);
    this.lastMsgId = thread.lastMsgIndex;
    this.dispatch?.(setThread(thread));

    const promises = [];
    promises.push(this.loadAvatar(thread.participants, getUserIdByType(thread.participants.find((participant) => participant.uid === thread.createdBy)), this.dispatch)); // for chat thread by twilio
    promises.push(_conv.getUnreadMessagesCount()
      .then((val) => {
        this.dispatch?.(setMsgUnreadCount(val === null ? 0 : val));
      })
      .catch((err) => {
        console.log(`Failed to get unread message count with ${JSON.stringify(err)}`);
      }));

    thread.participants.forEach((item) => {
      if (this.users[item.uid] === undefined) {
        this.users[item.uid] = null;
        promises.push(this.client.getUser(item.uid)
          .then((u) => {
            this.users[item.uid] = u;
            this.dispatch?.(updateUserOnlineStatus(u.identity, u.isOnline));
            this.users[item.uid].on('updated', (evt) => {
              evt.updateReasons.forEach((ur) => {
                if (ur === 'reachabilityOnline') this.dispatch?.(updateUserOnlineStatus(evt.user.identity, evt.user.isOnline));
              });
            });
          })
          .catch((err) => {
            console.log(`Failed to get user information for ${item.uid} with ${JSON.stringify(err)}`);
          }));
      }
    });
    promises.push(this.getMessages()
      .then((msgs) => {
        if (msgs.length > 0) this.dispatch?.(setMessages(msgs));
      })
      .catch((err) => {
        console.log(`Failed to get messages with ${JSON.stringify(err)}`);
      }));

    return new Promise((resolve) => {
      Promise.all(promises)
        .then(() => { resolve(); });
    });
  }

  getToken(uid: string): Promise<string> {
    return new Promise((resolve, reject) => {
      lambdaGetTwilioToken(uid)
        .then((token) => {
          resolve(token);
        })
        .catch(() => {
          reject(new Error('Failed with twilio token request'));
        });
    });
  }

  getFreeToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      httpGetFreeCommunityToken()
        .then((token) => {
          resolve(token);
        })
        .catch(() => {
          reject(new Error('Failed with twilio token request'));
        });
    });
  }

  afterRemoveMessage(message: TwilioMsg): Promise<void> {
    const { thread } = gstore.getState().chat;
    const { participants, sid } = thread;
    if (this.uid) {
      axios.post(accsiomURIs.twilio_notification_url, {
        type: 'remove',
        participants: participants.filter((part) => part.uid !== this.uid).map((part) => ({
          uid: part.uid,
          type: part.type,
          oid: part.oid
        })),
        sid,
        id: message.index
      }).catch(() => {});
    }

    return Promise.resolve();
  }

  deleteMessage(msgId: number): Promise<boolean> {
    gstore.dispatch(addCallApiCount());
    for (let i = 0; i < this.messages.length; i++) {
      if (this.messages[i].index === msgId) {
        this.dispatch?.(removeMessage(msgId));
        this.messages[i].remove().then(() => {
          // this.afterRemoveMessage(this.messages[i]);
        });
        this.messages.splice(i, 1);
        break;
      }
    }

    return Promise.resolve(true);
  }

  deleteMessages(msgIds: number[]): Promise<boolean> {
    gstore.dispatch(addCallApiCount());
    this.messages.forEach((message) => {
      if (msgIds.includes(message.index)) {
        this.dispatch?.(removeMessage(message.index));
        message.remove().then(() => {
          // this.afterRemoveMessage(message);
        });
      }
    });
    this.messages = this.messages.filter((message) => !msgIds.includes(message.index));

    return Promise.resolve(true);
  }

  createConversation(attrib: any, title: string, type: number, firstMsg?: string): Promise<string> {
    if (!this.client) return Promise.reject(new Error('Twilio client is not initiated'));
    gstore.dispatch(addCallApiCount());
    return new Promise((resolve, reject) => {
      this.client.createConversation({
        friendlyName: title,
        attributes: {
          ...(!firstMsg ? {} : {
            lastMessage: firstMsg,
            lastMessageTime: (new Date()).getTime()
          }),
          recipients: attrib,
          type
        }
      })
        .then((conv) => {
          conv.join()
            .then((joinedConv) => {
              lambdaCreateCommunityAdminParticipants(joinedConv.sid, attrib.filter((reiceipt) => reiceipt.uid !== this.uid).map((reiceipt) => reiceipt.uid))
                .then(() => {
                  resolve(joinedConv.sid);
                })
                .catch((err) => Promise.reject(new Error(`Invitation error: ${err}`)));
            })
            .catch((err) => Promise.reject(new Error(`Joining error: ${err}`)));
        })
        .catch((err) => {
          reject(new Error(`Creating conversation failed with error: ${JSON.stringify(err)}`));
        });
    });
  }

  addParticipantsToConversation(newRecipients: Contact[]): Promise<void> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    const promises = [];
    newRecipients.forEach((recipient) => {
      promises.push(this.conv.add(recipient.uid));
    });
    return new Promise((resolve) => {
      Promise.all(promises).then(() => {
        const att: any = this.conv.attributes;
        const { recipients } = att;
        att.recipients = [...recipients, ...newRecipients];
        this.conv.updateAttributes(att)
          .then(() => {
            this.dispatch?.(addRecipientsToThread(newRecipients));
            resolve();
          });
      });
    });
  }

  removeParticipantsFromConversation(uids: string[]): Promise<void> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    const promises = [];
    uids.forEach((uid) => {
      promises.push(this.conv.removeParticipant(uid));
    });
    return new Promise((resolve) => {
      Promise.all(promises).then(() => {
        const att: any = this.conv.attributes;
        att.recipients = att.recipients.filter((recipient) => !uids.includes(recipient.uid));
        this.conv.updateAttributes(att)
          .then(() => {
            this.dispatch?.(removeParticipants(uids));
            resolve();
          });
      });
    });
  }

  cleanConversation(): Promise<void> {
    this.messages.splice(0, this.messages.length);
    this.lastMsgId = -1;
    this.msgId = -1;
    const promises = [];
    Object.keys(this.users).forEach((uid) => {
      promises.push(this.users[uid]?.unsubscribe());
    });
    return new Promise((resolve) => {
      Promise.all(promises).then(() => {
        this.users = {};
        this.conv = null;
        this.dispatch?.(cleanUpChat());
        resolve();
      });
    });
  }

  deleteConversation(): Promise<void> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    return new Promise((resolve, reject) => {
      this.conv.delete()
        .then(() => {
          this.cleanConversation()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(new Error(`The conversation was failed to clean with Error: ${JSON.stringify(err)}`));
            });
        })
        .catch((err) => {
          reject(new Error(`The conversation was failed to delete with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  leaveConversation(deletedIds: string[]): Promise<void> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    return new Promise((resolve, reject) => {
      if (deletedIds.length < 1) {
        this.conv.leave()
          .then(() => {
            this.cleanConversation()
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(new Error(`The conversation was failed to clean with Error: ${JSON.stringify(err)}`));
              });
          })
          .catch((err) => {
            reject(new Error(`The conversation was failed to leave with Error: ${JSON.stringify(err)}`));
          });
      } else {
        this.removeParticipantsFromConversation(deletedIds)
          .then(() => {
            this.conv.leave()
              .then(() => {
                this.cleanConversation()
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    reject(new Error(`The conversation was failed to clean with Error: ${JSON.stringify(err)}`));
                  });
              })
              .catch((err) => {
                reject(new Error(`The conversation was failed to leave with Error: ${JSON.stringify(err)}`));
              });
          })
          .catch((err) => {
            reject(new Error(`The conversation was failed to remove other participants with Error: ${JSON.stringify(err)}`));
          });
      }
    });
  }

  getConversation(sid: string): Promise<void> {
    if (!this.client) return Promise.reject(new Error('Twilio client is not initiated.'));
    gstore.dispatch(addCallApiCount());
    return new Promise((resolve, reject) => {
      this.cleanConversation()
        .then(() => {
          this.client.getConversationBySid(sid)
            .then((_conv) => {
              this.setConvDispatch(_conv)
                .then(() => { resolve(); });
            })
            .catch((err) => {
              reject(new Error(`Get conversations failed with Error: ${JSON.stringify(err)}`));
            });
        })
        .catch((err) => {
          reject(new Error(`Clean conversations failed with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  updateLastReadMsgIndex(): Promise<void> {
    gstore.dispatch(addCallApiCount());
    this.dispatch?.(markThreadAsSeen());
    this.dispatch?.(updateLastReadMsgIndex(this.lastMsgId));
    this.conv.setAllMessagesRead().then(() => {
      if (this.uid) {
        axios.post(accsiomURIs.twilio_notification_url, {
          type: 'saw',
          uid: this.uid,
          sid: this.conv.sid
        }).catch(() => {});
      }
    }).catch(() => {});
    // conv.updateLastReadMessageIndex(this.lastMsgId[conv.sid]).catch(() => {});

    return Promise.resolve();
  }

  onTyping(): void {
    if (!this.conv) return;
    this.conv.typing()
      .then(() => {})
      .catch(() => {});
  }

  getMessages(from?: number, direction?: 'backwards' | 'forward'): Promise<AccsiomMsg[]> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    const ret: AccsiomMsg[] = [];
    const twilioMsgs : TwilioMsg[] = [];
    return new Promise((resolve, reject) => {
      this.conv.getMessages(PAGE_SIZE, from, direction)
        .then((paginator) => {
          paginator.items.forEach((item) => {
            const aMsg = TwilioToAccsiomMsg(item);
            twilioMsgs.push(item);
            const { files } = aMsg.body;
            files.forEach(({ type, uri }) => {
              if (type !== undefined) {
                if (type.includes('image/')) {
                  this.loadImageThumbnail(uri, aMsg);
                }
                if (type.includes('video/mp4')) {
                  this.loadVideoThumbnail(uri, aMsg);
                }
              }
            });
            ret.push(aMsg);
          });
          if (direction === 'forward') {
            this.messages.push(...twilioMsgs);
          } else {
            this.messages.unshift(...twilioMsgs);
          }
          resolve(ret);
        })
        .catch((err) => {
          reject(new Error(`Get messages failed with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  changeReaction(msgId: number, emoji: string): Promise<boolean> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    const msg = this.messages.find((item) => item.index === msgId);
    if (!msg) return Promise.reject(new Error('Message not exists'));
    gstore.dispatch(addCallApiCount());
    const attrib = msg.attributes as MessageAttribue;
    let newReactions = attrib.reactions;
    if (attrib.reactions.some(({ userId }) => userId === this.uid)) {
      newReactions = attrib.reactions.map((v) => {
        if (v.userId === this.uid) {
          if (v.marks.includes(emoji)) {
            return {
              userId: v.userId,
              marks: v.marks.filter((vv) => vv !== emoji)
            };
          }
          return {
            userId: v.userId,
            marks: [...v.marks, emoji]
          };
        }
        return v;
      });
    } else {
      newReactions = [...attrib.reactions, {
        userId: this.uid,
        marks: [emoji]
      }];
    }
    const newAttrib = {
      ...attrib,
      reactions: newReactions
    };
    return new Promise((resolve) => {
      msg.updateAttributes(newAttrib).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(true);
      });
      // msg.updateBody(_body)
      //   .then(() => {
      //     this.dispatch?.(setEditingMsg('', 0));
      //     resolve(true);
      //   })
      //   .catch(() => {
      //     this.dispatch?.(setEditingMsg('', 0));
      //     resolve(true);
      //   });
    });
  }

  removeFile(msgId: number, uri: string): Promise<boolean> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    const msg = this.messages.find((item) => item.index === msgId);
    if (!msg) return Promise.reject(new Error('Message not exists'));
    gstore.dispatch(addCallApiCount());
    const body = JSON.parse(msg.body);
    const newBody = {
      ...body,
      files: body.files.filter((f) => f.uri !== uri)
    };
    return new Promise((resolve) => {
      msg.updateBody(JSON.stringify(newBody))
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  updateMessage(msgId: number, content: string): Promise<boolean> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    const msg = this.messages.find((item) => item.index === msgId);
    if (!msg) return Promise.reject(new Error('Message not exists'));
    gstore.dispatch(addCallApiCount());
    return new Promise((resolve) => {
      msg.updateBody(JSON.stringify({
        ...JSON.parse(msg.body),
        textContent: content
      }))
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  sendQuote(_body: string, attrib?: any): Promise<number> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    this.dispatch?.(msgAdded({
      id: this.msgId,
      // body: _body, TODO
      body: null,
      convId: this.conv.sid,
      attrib,
      createdAt: (new Date()).getTime(),
      sent: false,
      senderId: this.uid
    }));
    this.msgId--;
    return new Promise((resolve, reject) => {
      this.conv.sendMessage(_body, attrib)
        .then((val) => {
          if (this.uid === this.conv.createdBy) {
            const att: any = this.conv.attributes;
            att.lastMessage = _body;
            att.lastMessageTime = (new Date()).getTime();
            this.conv.updateAttributes(att);
          }
          resolve(val);
        })
        .catch((err) => {
          reject(new Error(`Send message failed with error : ${err}`));
        });
    });
  }

  sendMessage(textType: number, textContent: string, files: any[], replyTo: any): Promise<number> {
    if (!this.conv) return Promise.reject(new Error('Conversation not exists'));
    gstore.dispatch(addCallApiCount());
    const body = {
      id: uuidv4(),
      textType,
      textContent,
      replyTo,
      files
    } as MessageBody;
    const newMsg = {
      id: this.msgId,
      body,
      convId: this.conv.sid,
      attrib: { reactions: [] },
      createdAt: (new Date()).getTime(),
      sent: false,
      senderId: this.uid
    };
    this.dispatch?.(msgAdded(newMsg));
    files.forEach(({ type, uri }) => {
      if (type !== undefined) {
        if (type.includes('image/')) {
          this.loadImageThumbnail(uri, newMsg);
        }
        if (type.includes('video/mp4')) {
          this.loadVideoThumbnail(uri, newMsg);
        }
      }
    });
    this.msgId--;
    return this.conv.sendMessage(JSON.stringify(body), newMsg.attrib);
  }
}

export const chatApi = new ChatApi();
