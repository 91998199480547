export const fetchUrl = async (url: string) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return URL.createObjectURL(new Blob([buf]));
};

export const uploadFile = async (file: File | Blob, url: string): Promise<any> => fetch(url, {
  method: 'put',
  headers: {
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': ''
  },
  body: file,
})
  .then((reponse) => Promise.resolve(reponse));
