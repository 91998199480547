// created by Makarov --2021/11/01

export const PAGE_SIZE = 20;
export const PRODUCT_PAGE_SIZE = 12;
export const ITEM_PAGE_SIZE = 10;
export const INVITATION_PAGE_START = 2;
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51L7w51D5b37GdQHI8J35rJRRrW909ledxjcCDkWeOqOAY4R4elZ20wWovOBbJctGWxzQkbkOhn950RBMTzMEgEHo005jr4zcWm';
export const WALLET_HISTORY_PAGE_SIZE = 15;
export const MESSAGE_MAX_LENGTH = 250;

export const tenary = (condition, then, otherwise): any => {
  if (condition) {
    return then;
  }

  return otherwise;
};

export const getStreetInfoFromAddr = (addr: string): { streetName, streetNumber } => {
  const streetInfo = addr.split(',').at(0);
  const streetNumber = streetInfo.split(' ').at(0).trim();
  const streetName = streetInfo.replace(`${streetNumber} `, '').trim();
  return { streetName, streetNumber };
};

export const isValidEmail = (custom_str: string): boolean => {
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(custom_str.toLowerCase());
};

export const TAB_LABEL_ACCZIOM_USERS: string = 'Accziom Users';
export const TAB_LABEL_MY_CONTACTS: string = 'Contacts';
export const TAB_LABEL_PARTNERS: string = 'Partners';
export const TAB_LABEL_ORG_CLIENTS: string = 'Clients';
export const TAB_LABEL_ORG_SUPPLIERS: string = 'Suppliers';
export const TAB_LABEL_ORG_MEMBERS: string = 'Members';
export const TAB_VALUE_ACCZIOM_USERS: string = 'Accziom user';
export const TAB_VALUE_MY_CONTACTS: string = 'contact';
export const TAB_VALUE_PARTNERS: string = 'partner';
export const TAB_VALUE_ORG_CLIENTS: string = 'client';
export const TAB_VALUE_ORG_SUPPLIERS: string = 'supplier';
export const TAB_VALUE_ORG_MEMBERS: string = 'member';

export const INVITE_ITEM_HEIGHT: number = 80;

export const TAB_LABEL_MICROSERVICES: string = 'Microservices';
export const TAB_LABEL_GOODS: string = 'Goods';
export const TAB_LABEL_ASSETS: string = 'Assets';
export const TAB_VALUE_MICROSERVICES: string = 'microservices';
export const TAB_VALUE_GOODS: string = 'goods';
export const TAB_VALUE_ASSETS: string = 'assets';

export const DELETE_SINGLE_MESSAGE_TITLE: string = 'Delete Message';
export const DELETE_SINGLE_MESSAGE_CONTENT: string = 'Are you sure want to permanently delete this message?';
export const DELETE_MESSAGES_TITLE: string = 'Delete Messages';
export const DELETE_MESSAGES_CONTENT: string = 'Are you sure want to permanently delete these messages?';
export const DELETE_ROLE_TITLE: string = 'Delete Role';
export const DELETE_ROLE_CONTENT: string = 'Are you sure want to permanently delete this role and some members assigned to it?';
export const DELETE_TEAM_TITLE: string = 'Delete Team';
export const DELETE_TEAM_CONTENT: string = 'Are you sure want to permanently delete this team?';
export const DELETE_MEMBER_TITLE: string = 'Delete Member';
export const DELETE_MEMBER_CONTENT: string = 'Are you sure want to permanently release this member?';
export const DELETE_CONTACT_TITLE: string = 'Delete Contact';
export const DELETE_CONTACT_CONTENT: string = 'Would you mind remove this contact for sure?';
export const DELETE_THREAD_TITLE: string = 'Terminate Channel';
export const DELETE_THREAD_CONTENT: string = 'Are you sure want to permanently archive this channel?';
export const LEAVE_THREAD_TITLE: string = 'Leave Channel';
export const LEAVE_THREAD_CONTENT: string = 'Are you sure want to permanently leave this channel?';
export const LEAVE_WINDOW_TITLE: string = 'Leave Window';
export const LEAVE_WINDOW_CONTENT: string = 'Are you sure continue to leave this window? All of your work in this window will be crashed.';
export const CONFIRM_SCHEDULE_TITLE: string = 'Confirm Schedules';
export const CONFIRM_SCHEDULE_CONTENT: string = 'Are you sure want to accept these schedules?';
export const DELETE_SCHEDULE_TITLE: string = 'Delete Schedules';
export const DELETE_SCHEDULE_CONTENT: string = 'Are you sure want to permanently delete all schedules related to this one?';

export const getInviteEmailSubject = (userName: string): string => {
  const invitorName = userName.length > 0 ? userName : 'Anonymous Accziom-user';
  return `${invitorName} invited you to join Accziom`;
};

export const getInviteEmailBody = (receiver: string, sender: string): string => {
  const invitorName = sender.length > 0 ? sender : 'Anonymous Accziom-user';
  const receiverName = receiver.length > 0 ? `. ${receiver},` : ',';
  const sentence1 = `Dear Mr/Mrs${receiverName}\n\n`;
  const sentence2 = `${invitorName} invites you to join us on Accziom - a business collaboration platform. Please click the link below and sign-up.\n`;
  const sentence3 = 'If you have issues, don\'t hesitate to contact us at support@mail.accziom.com\n';
  return `${sentence1}${sentence2}${sentence3}`;
};

export const getConnectEmailSubject = (userName: string): string => {
  const invitorName = userName.length > 0 ? userName : 'Anonymous Accziom-user';
  return `${invitorName} invited partner`;
};

export const getConnectEmailBody = (receiver: string, sender: string): string => {
  const invitorName = sender.length > 0 ? sender : 'Anonymous Accziom-user';
  const receiverName = receiver.length > 0 ? `, ${receiver}.` : '.';
  const sentence1 = `Hello${receiverName}\n\n`;
  const sentence2 = 'We sincerely hope to become a trading partner with you in Accziom.\n';
  const sentence3 = 'Thanks for your help.\n\n';
  const sentence4 = `From ${invitorName}.`;
  return `${sentence1}${sentence2}${sentence3}${sentence4}`;
};

export const convertTextToHtml = (srcText: string): string => {
  let previousWasASpace: boolean = false;
  let destText: string = '';
  for (let i = 0; i < srcText.length; i++) {
    const oneLetter = srcText.charAt(i);
    if (oneLetter === ' ') {
      if (previousWasASpace) {
        destText = `${destText}&nbsp;`;
        previousWasASpace = false;
      } else {
        previousWasASpace = true;
      }
    } else {
      previousWasASpace = false;
    }
    switch (oneLetter) {
      case '<':
        destText = `${destText}&lt;`;
        break;
      case '>':
        destText = `${destText}&gt;`;
        break;
      case '&':
        destText = `${destText}&amp;`;
        break;
      case '"':
        destText = `${destText}&quot;`;
        break;
      case '\n':
        destText = `${destText}<br />`;
        break;
      default:
        destText = `${destText}${oneLetter}`;
        break;
    }
  }
  return `<p>${destText}</p>`;
};

export const isNullForHtmlText = (text: string): boolean => (!text || text === convertTextToHtml('') || text === '<p><br></p>');

export const convertHtmlToText = (srcHtml: string): string => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = srcHtml;
  return tmp.textContent || tmp.innerText || '';
};

export const linkifyText = (inputText: string): string => {
  let replacedText;

  // URLs starting with http://, https://, or ftp://
  // eslint-disable-next-line
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // eslint-disable-next-line
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  // Change email addresses to mailto:: links.
  // eslint-disable-next-line
  const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const contactFields = [
  {
    index: '0',
    label: 'Display Name',
    value: 'displayName',
    needed: true
  },
  {
    index: '1',
    label: 'First Name',
    value: 'firstName',
    needed: false
  },
  {
    index: '2',
    label: 'Middle Name',
    value: 'middleName',
    needed: false
  },
  {
    index: '3',
    label: 'Last Name',
    value: 'lastName',
    needed: false
  },
  {
    index: '4',
    label: 'Legal Name',
    value: 'legalName',
    needed: false
  },
  {
    index: '5',
    label: 'Trading Name',
    value: 'tradingName',
    needed: false
  },
  {
    index: '6',
    label: 'Casual Name',
    value: 'casualName',
    needed: false
  },
  {
    index: '7',
    label: 'Title',
    value: 'title',
    needed: false
  },
  {
    index: '8',
    label: 'Note',
    value: 'note',
    needed: false
  },
  {
    index: '9',
    label: 'Phone',
    value: 'primaryPhone',
    needed: true
  },
  {
    index: '10',
    label: 'Mobile',
    value: 'mobilePhone',
    needed: false
  },
  {
    index: '11',
    label: 'Fax',
    value: 'faxPhone',
    needed: false
  },
  {
    index: '12',
    label: 'Email',
    value: 'primaryEmail',
    needed: true
  },
  {
    index: '13',
    label: 'Tax Number',
    value: 'taxNumber',
    needed: false
  },
  {
    index: '14',
    label: 'Street',
    value: 'street',
    needed: false
  },
  {
    index: '15',
    label: 'Suburb',
    value: 'suburb',
    needed: false
  },
  {
    index: '16',
    label: 'State',
    value: 'state',
    needed: false
  },
  {
    index: '17',
    label: 'Postcode',
    value: 'postcode',
    needed: false
  },
  {
    index: '18',
    label: 'Website',
    value: 'website',
    needed: false
  },
  {
    index: '19',
    label: 'Entity Type',
    value: 'entityType',
    needed: false
  },
  {
    index: '20',
    label: 'ABN',
    value: 'abn',
    needed: false
  },
  {
    index: '21',
    label: 'ACN',
    value: 'acn',
    needed: false
  },
  {
    index: '22',
    label: 'Individual',
    value: 'isIndividual',
    needed: false
  },
];

export const emptyAddress = {
  reason: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  region: '',
  postalCode: '',
  country: ''
};

export const initializedContact = {
  isIndividual: false,
  displayName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  legalName: '',
  tradingName: '',
  casualName: '',
  title: '',
  note: '',
  physicalAddress: { ...emptyAddress, addressType: 'PHYSICAL' },
  postalAddress: { ...emptyAddress, addressType: 'POBOX' },
  additionalAddresses: [],
  addresses: [],
  primaryPhone: '',
  mobilePhone: '',
  faxPhone: '',
  ddiPhone: '',
  additionalPhones: [],
  phoneNumbers: [],
  primaryEmail: '',
  alternativeEmail: '',
  additionalEmails: [],
  emails: [],
  taxNumber: '',
  website: '',
  entityType: 'Trust',
  abn: '',
  acn: ''
};

export const contactSubKeys = [
  'primaryEmail',
  'alternativeEmail',
  'additionalEmails',
  'primaryPhone',
  'mobilePhone',
  'faxPhone',
  'ddiPhone',
  'additionalPhones',
  'physicalAddress',
  'postalAddress',
  'billingAddress',
  'streetAddress',
  'additionalAddresses'
];

export interface DblButtonMessageProps {
  onYesClick?: () => void;
  onNoClick?: () => void;
  messageTitle: string;
  messageContent: string;
  onClose?: (data: boolean) => void;
}

export interface SingButtonMessageProps {
  buttonTitle?: string;
  onOkClick?: any;
  messageContent: string[];
  onClose?: (data: boolean) => void;
}

export const CRYPTO_ETHER = 'Ether';
export const CRYPTO_TETHER = 'Tether';
export const CRYPTO_MRC = 'Merchant';
export const FIAT_AUD = 'AUD';
export const FIAT_USD = 'USD';

export const tokenData = [
  {
    name: CRYPTO_ETHER,
    title: 'Ethereum',
    unit: 'ETH',
    icon: '/static/ethereum.png',
    color: '#627EEA'
  },
  {
    name: CRYPTO_TETHER,
    title: 'Tether USD',
    unit: 'USDT',
    icon: '/static/tether-usdt.svg',
    color: '#53AE94'
  },
  {
    name: CRYPTO_MRC,
    title: 'Merchant Coin',
    unit: 'MERC',
    icon: '/static/MERc.png',
    color: '#226822'
  }
];

export const cryptoCurrencies = [CRYPTO_ETHER, CRYPTO_TETHER, CRYPTO_MRC];
export const fiatCurrencies = [FIAT_AUD, FIAT_USD];
export const allCurrencyUnits = ['ETH', 'USDT', 'MERC', 'AUD', 'USD'];

export const fiatData = [
  {
    name: FIAT_AUD,
    title: 'Australian Dollar',
    unit: FIAT_AUD,
    icon: '/static/Australia.png'
  },
  {
    name: FIAT_USD,
    title: 'US Dollar',
    unit: FIAT_USD,
    icon: '/static/United-States.png'
  }
];

export const currencyTypeToUnit = (type: string) => (
  cryptoCurrencies.includes(type)
    ? tokenData.find((v) => v.name === type).unit
    : type
);

export const currencyUnitToType = (unit: string) => (
  fiatCurrencies.includes(unit)
    ? unit
    : tokenData.find((v) => v.unit === unit).name
);

export const REOFFERDRAFT = -1;
export const REOFFERED = 0;
export const OFFERDRAFT = 1;
export const OFFERED = 2;
export const OFFERREQUESTDRAFT = 3;
export const DRAFT = 4;
export const REQUESTED = 5;
export const PROGRESSING = 6;
export const REJECTED = 7;
export const ARCHIVED = 8;
export const NEGOTIATION = 9;
export const CUSTOMER_REMOVED = 10;
export const SUPPLIER_REMOVED = 11;

export const TICKET_MODE_NORMAL = 1;
export const TICKET_MODE_PROPOSAL = 2;

export const NONE_TYPE_ITEM = 0;
export const MICROSERVICE = 1;
export const GOOD = 2;
export const MICROSERVICE_BUNDLE = 3;
export const ASSET = 4;
export const GOOD_BUNDLE = 5;
export const ASSET_BUNDLE = 6;
export const CHAT = 7;
export const CONTRACT = 8;
export const FILETHREAD = 9;
export const TICKET = 10;
export const INVOICE = 11;
export const RFQ = 12;
export const PURCHASEORDER = 13;
export const FRIEND = 14;
export const REFERRAL = 15;
export const SCHEDULE = 16;
export const PURCHASEDITEM = 17;
export const BS_FAVORITE = 18;
export const BS_SEARCH_HISTORY = 19;
export const INFORMATION = 20;
export const ORGJOIN = 21;

export const null2empty = (value: any) => {
  if (value === null || value === undefined) return '';
  return value;
};

export const ACCZIOM_NONE = 0;
export const ACCZIOM_USER = 1;
export const ACCZIOM_ORG = 2;
export const ACCZIOM_TEAM = 3;
export const ACCZIOM_MEMBER = 4;
export const ACCZIOM_CLIENT = 5;
export const ACCZIOM_CLIENT_ORG = 6;
export const ACCZIOM_SUPPLIER = 7;
export const ORG_TYPES = [ACCZIOM_ORG, ACCZIOM_CLIENT_ORG, ACCZIOM_SUPPLIER];
export const INDIVIDUAL_TYPES = [ACCZIOM_USER, ACCZIOM_MEMBER, ACCZIOM_CLIENT];

export const getUserIdByType = (body: any): string => {
  if (body) {
    if (INDIVIDUAL_TYPES.includes(body.type)) return body.uid;
    if (ORG_TYPES.includes(body.type)) return body.oid;
  }
  return '';
};

export const getUserTypeByType = (body: any): number => {
  if (body) {
    if (ORG_TYPES.includes(body.type)) return ACCZIOM_ORG;
    return body.type;
  }
  return ACCZIOM_NONE;
};

export const getUserInfoByType = (body: any): any => ({ id: getUserIdByType(body), type: getUserTypeByType(body) });

export const USUAL_CHAT_THREAD = 0;
export const REQUEST_CHAT_THREAD = 1;
export const INNERORG_CHAT_THREAD = 2;
export const PROPOSAL_CHAT_THREAD = 3;
export const CONTRACT_CHAT_THREAD = 4;
export const FRIEND_CHAT_THREAD = 5;
export const REFERRAL_CHAT_THREAD = 6;
export const COMMUNITY_CHAT_THREAD = 7;

export const GOOD_PAGE_SIZE = 12;

export const TEMPLATE_DRAFT = 0;
export const TEMPLATE_PUBLISHED = 1;

export const NO_MEMBER_MODE = 0;
export const CHAT_MEMBER_MODE = 1;
export const ALL_MEMBER_MODE = 2;

export const NO_USER_MODE = 0;
export const ONLY_USER_MODE = 1;
export const ONLY_ORG_MODE = 2;
export const BOTH_USER_ORG_MODE = 3;

export const NO_TITLE_FIRSTMSG = 0;
export const ONLY_TITLE = 1;
export const ONLY_FIRSTMSG = 2;
export const BOTH_TITLE_FIRSTMSG = 3;

export const PRICE_PER_UNIT = 0;
export const TOTAL_PRICE = 1;
export const priceOptionModel = [
  {
    value: `${PRICE_PER_UNIT}`,
    label: 'Price Per Unit'
  },
  {
    value: `${TOTAL_PRICE}`,
    label: 'Total Price'
  }
];

export const FINANCIAL_ASSET = 0;
export const PLANT_EQUIPMENT = 1;
export const LAND_BUILDING = 2;
export const INTANGIBLE_ASSET = 3;
export const MOTOR_VEHICLE = 4;
export const TODO_RESOURCE_COMMENT = 0;
export const TODO_RESOURCE_ATTACHMENT = 1;

export const DELIVERY_MODE = 0;
export const PAYMENT_MODE = 1;
export const REFERRAL_MODE = 2;

export const partsOfContract = {
  delivery: DELIVERY_MODE,
  payment: PAYMENT_MODE
};

export const TRADE_CONTRACT = 0;
export const FINANCE_CONTRACT = 1;
export const contractTypeModel = [
  {
    value: `${TRADE_CONTRACT}`,
    label: 'Trade Contract'
  },
  {
    value: `${FINANCE_CONTRACT}`,
    label: 'Finance Contract'
  }
];

export const SIMPLE_INTEREST_TYPE = 0;
export const COMPOUND_INTEREST_TYPE = 1;
export const interestTypeModel = [
  {
    value: `${SIMPLE_INTEREST_TYPE}`,
    label: 'Simple Interest'
  },
  {
    value: `${COMPOUND_INTEREST_TYPE}`,
    label: 'Compound Interest'
  }
];

export const ON_COMMENCEMENT = 0;
export const ON_COMPLETION = 1;
export const paymentPointDelivery = [
  {
    value: `${ON_COMMENCEMENT}`,
    label: 'commencement'
  },
  {
    value: `${ON_COMPLETION}`,
    label: 'completion'
  }
];

export const NOT_REQUIRED_PO = 0;
export const REQUIRED_PO = 1;

export const CONTRACT_MODE = 0;
export const PURCHASE_ORDER_MODE = 1;
export const scheduleFromModel = [
  {
    value: `${CONTRACT_MODE}`,
    label: 'Contract'
  },
  {
    value: `${PURCHASE_ORDER_MODE}`,
    label: 'Purchase Order'
  }
];

export const FROM_NONE = 0;
export const FROM_PROPOSAL = 1;
export const FROM_REQUEST = 2;

export const BUNDLE_GOLD_LIST = 0;
export const BUNDLE_SILVER_LIST = 1;
export const BUNDLE_BRONZE_LIST = 2;

export const SERVICE_BUNDLE_TYPES = [BUNDLE_BRONZE_LIST, BUNDLE_SILVER_LIST, BUNDLE_GOLD_LIST];

export const DAY_MILISECONDS = 86400000;
export const MINUTE_MILISECONDS = 60000;

export const PAY_CREDIT_CARD = 1;
export const PAY_DIRECT_DEBIT = 2;

export const PAY_STRIPE = 1;
export const PAY_TEXT_STRIPE = 'stripe';
export const PAY_BIZCORE = 2;
export const PAY_TEXT_BIZCORE = 'bizcore';
export const PAY_CRYPTOCURRENCY = 3;
export const PAY_TEXT_CRYPTOCURRENCY = 'cryptocurrency';

export const STATUS_PENDING = 0;
export const STATUS_ACTIVATED = 1;
export const STATUS_DECLINED = 2;
export const STATUS_DEACTIVATED = 3;
export const STATUS_RECOMMENDED = 4;
export const STATUS_REJECTED = 5;
export const STATUS_NORMAL = 6;
export const STATUS_LOANED = 7;
export const STATUS_LEASED = 8;
export const STATUS_BROKEN = 9;
export const STATUS_EXPIRED = 10;
export const STATUS_COMPLETED = 11;
export const STATUS_REMOVED = 12;
export const STATUS_AVAILABLE = 13;
export const STATUS_RUNNING = 14;
export const STATUS_PENDING_TO_ORG = 15;
export const STATUS_ALL = 16;

export const BUYER_TO_SELLER = 1;
export const SELLER_TO_BUYER = 2;

export const ITEM_VIEW = 1;

export const TEAM_ITEM_MODE = 1;
export const ORG_ITEM_MODE = 2;
export const REFERED_ITEM_MODE = 3;

export const ONLY_ITEM_MODE = 1;
export const ONLY_BUNDLE_MODE = 2;
export const BOTH_ITEM_BUNDLE_MODE = 3;

export const FIND_SELLER_TYPE = 1;
export const FIND_BUYER_TYPE = 2;
export const findRefereeTypes = [
  {
    value: `${FIND_SELLER_TYPE}`,
    label: 'seller'
  },
  {
    value: `${FIND_BUYER_TYPE}`,
    label: 'buyer'
  }
];

export const ALL_USER_MODE = 0;
export const ONLY_PARTNER_MODE = 1;
export const PARTNER_ME_MODE = 2;

export const UI_PADDING = {
  sm: '140px',
  md: '140px',
  lg: 'calc(3% + 140px)',
  xl: 'calc(8% + 140px)'
};

export const NAVBAR_HEIGHT = 72;
export const SEARCHBAR_HEIGHT = 72;
export const NAVBUTTON_WIDTH = 500;
export const SIDEBAR_WIDTH = 25;

export function CopyToClipboard(text) {
  navigator.clipboard.writeText(text);
}

export const IPFS_UPLOAD = 1;
export const AWS_UPLOAD = 2;

export const VERIFY_NONE = 0;
export const VERIFY_PROGRESSING = 1;
export const VERIFY_COMPLETED = 2;

export const SERVICE_REQUEST_STEP_INFOCOLLECT = 0;
export const SERVICE_REQUEST_STEP_CONFIRMED = 1;
export const SERVICE_REQUEST_STEP_COMPLETED = 2;

export const SERVICE_OFFER_STEP_INFOCOLLECT = 0;
export const SERVICE_OFFER_STEP_SELECT_CUSTOMERS = 1;

export const SORT_OPTIONS = [
  { label: 'Recently Added', value: 'Descending' },
  { label: 'Recently Updated', value: 'Updated' },
  { label: 'Name', value: 'Ascending' }
];

export const MSG_NONE = 0;
export const MSG_REMOVE = 1;
export const MSG_REMOVE_WITH_OTHER = 2;
export const MSG_REJECT = 3;
export const MSG_SUPPRESS = 4;
export const MSG_NOT_REMOVE = 5;
export const MSG_LEAVE = 6;
export const MSG_ARCHIVE = 7;
export const MSG_NAVIGATE = 8;
export const MSG_REJECT_FOLLOWUP = 9;

export const SUBMIT_NONE = 0;
export const SUBMIT_TO_READY = 1;
export const SUBMIT_PUBLISH = 2;
export const SUBMIT_DUPLICATE = 3;
export const SUBMIT_REMOVE = 4;
export const SUBMIT_SIGN = 5;
export const SUBMIT_PURCHASE = 6;
export const SUBMIT_REJECT = 7;
export const SUBMIT_DOWNLOAD = 8;
export const SUBMIT_CREATE = 9;
export const SUBMIT_INVITE = 10;
export const SUBMIT_AGAIN = 11;
export const SUBMIT_ARCHIVE = 12;
export const SUBMIT_REJECT_FOLLOWUP = 13;
export const SUBMIT_SUPPRESS = 14;
export const SUBMIT_REGISTER = 15;
export const SUBMIT_RETURN = 16;
export const SUBMIT_CHAT = 17;
export const SUBMIT_POSTPONE = 18;
export const SUBMIT_CHANGE = 19;
export const SUBMIT_ACCEPT = 20;
export const SUBMIT_ACCEPT_FOLLOWUP = 21;
export const SUBMIT_JOIN = 22;

export const SHOW_NONE = 0;
export const SHOW_DETAIL = 1;
export const SHOW_VERIFY = 2;
export const SHOW_SHARE = 3;
export const SHOW_INVITE_MSG = 4;
export const SHOW_ADD_ITEM = 5;
export const SHOW_INVITE_USER = 6;

export const BEFORE_RESULT = -1;
export const RESULT_FALSE = 0;
export const RESULT_TRUE = 1;

export const etherscanEndpoint = 'https://goerli.etherscan.io';

export const ASSET_PURPOSE_KEEP = 0;
export const ASSET_PURPOSE_SALE = 1;
export const ASSET_PURPOSE_LEASE = 2;

export const RESOURCE_FILE_TYPE_IMAGE = 0;
export const RESOURCE_FILE_TYPE_VIDEO = 1;
export const RESOURCE_FILE_TYPE_FILE = 2;

export const BANNER_RATIO = '4 / 1';

export const PLATFORM_QBO = 'QBO';
export const PLATFORM_XERO = 'XERO';

export const INVITATION_EXPIRE_DAY = 30;

export const SELECT_PARTNER_ORG_MODE = 1;
export const SELECT_PARTNER_CLIENT_MODE = 2;
export const SELECT_PARTNER_PARTNER_MODE = 3;
export const SELECT_PARTNER_MEMBER_MODE = 4;
export const SELECT_PARTNER_SUPPLIER_MODE = 5;

export const NONE_BUTTON_MODE = 0;
export const FUNCTION_BUTTON_MODE = 1;
export const MORE_BUTTON_MODE = 2;

export const REQUIRE_NONE_MODE = 0;
export const REQUIRE_NOT_AUTH_MODE = 1;
export const REQUIRE_AUTH_MODE = 2;

export const participantStatusList = [
  {
    id: STATUS_PENDING,
    value: 'Pending',
    color: '#ff8a65'
  },
  {
    id: STATUS_DECLINED,
    value: 'Denied',
    color: '#ff8a65'
  },
  {
    id: STATUS_ACTIVATED,
    value: 'Active',
    color: '#d4f6cc'
  }
];

export const BACKGROUND_DEEP = '#49759c';

export const defaultTeamData = [
  'Sales',
  'Advertising / Marketing',
  'Operations',
  'Software Development / Engineering',
  'Partnerships',
  'Legal',
  'Finance / Accounting'
];

export const scheduleDiagramStyle = {
  dayWidth: 21,
  lineHeight: 30,
  timeHeight: 30,
  titleWidth: 300,
  barPadding: 5,
  barBorderRadius: 8,
  borderColor: '#eeeeee',
  barColor: '#1695eaa0',
  weekLineColor: '#aaaaaa',
  weekendColor: '#fafafa',
  detailTextColor: '#303030',
  payColor: '#d03030'
};

export const fullTags = [
  {
    type: 'Apps & functions',
    tags: [
      'Accounting systems',
      'Bills & expenses',
      'CRM systems',
      'System Conversion',
      'Custom Integration',
      'Debtor tracking',
      'Ecommerce',
      'Financial services',
      'Inventory',
      'Invoicing & jobs',
      'Payments',
      'Payroll HR',
      'Point of sale',
      'Practice management',
      'Reporting',
      'Time tracking',
      'Document systems'
    ],
    color: '#4caf50'
  },
  {
    type: 'Tax Tags',
    tags: [
      'Goods & services tax',
      'Fuel tax',
      'Income tax',
      'Luxury car tax',
      'Wine equalization tax',
      'Payroll tax',
      'Fringe Benefits Tax'
    ],
    color: '#ff9800'
  },
  {
    type: 'Activities',
    tags: [
      'Bookkeeping',
      'Payroll',
      'Accounting',
      'Advisory',
      'Corporate Affairs'
    ],
    color: '#9c27b0'
  },
  {
    type: 'Industry tags',
    tags: [
      'Agriculture, Forestry and Fishing',
      'Mining',
      'Manufacturing',
      'Electricity, Gas, Water and Waste Services',
      'Construction',
      'Wholesale Trade',
      'Retail Trade',
      'Accommodation and Food Services',
      'Transport, Postal and Warehousing',
      'Information Media and Telecommunications',
      'Financial and Insurance Services',
      'Rental, Hiring and Real Estate Services',
      'Professional, Scientific and Technical Services',
      'Administrative and Support Services',
      'Public Administration and Safety',
      'Education and Training',
      'Health Care and Social Assistance',
      'Arts and Recreation Services'
    ],
    color: '#673ab7'
  }
];

export const MESSAGE_TEXT = 1;
export const MESSAGE_HTML = 2;

export const HOVER_BGCOLOR = '#fafafa';
export const BOXSHADOW = '0px 0px 2px #00000070';
export const THEME_COLOR_GRAY = '#fafafa';
export const THEME_COLOR_DARK_GRAY = '#e8e8e8';
export const BOXSHADOW2 = '0px 2px 4px #0a164612';

export const FREE_USER_ID = 'FREE_USER_ID';

export const IDLE_DURATION_MINUTES = 120;
export const CHECK_INTERVAL_MINUTES = 5;
