import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
// import Login from 'src/pages/authentication/Login';
import { useDispatch, useSelector } from 'src/store';
import { LoadingStatus } from 'src/slices/user';
import SpinnerScreen from './SpinnerScreen';
import initialLoad, { initializeStates } from 'src/worker/init';
import toast from 'react-hot-toast';

interface AuthGuardProps {
  children: ReactNode;
  isFree?: boolean;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { children, isFree } = props;
  const auth = useAuth();
  const { userInfo, domainName, loadingStatus } = useSelector((state) => state.user);
  const [error, setError] = useState<string>(null);

  const handleLogout = async (): Promise<void> => {
    try {
      await auth.logout();
      initializeStates(dispatch);
      navigate('/home');
    } catch (err) {
      console.log(err);
      toast.error('Unexpected error occured, Please try again!');
    }
  };

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    if (loadingStatus === LoadingStatus.NONE) {
      let subdomain = domainName;
      if (!subdomain) {
        if (process.env.NODE_ENV === 'production') {
          const domain = window.location.hostname;
          if (domain !== 'community.accziom.com') {
            const idx = domain.indexOf('.community.accziom.com');
            if (idx > 0) subdomain = domain.substring(0, idx);
            else setError('The organization domain not exists!');
          }
        }
      }
      initialLoad(auth.user.id, auth.checkLive, dispatch, handleLogout, subdomain);
    }
  }, [auth, userInfo, loadingStatus]);

  if (error) return <Navigate to="/error" />;

  if (!auth.isAuthenticated) {
    if (!isFree) {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = 'https://community.accziom.com';
        return <SpinnerScreen />;
      }
      return <Navigate to="/" />;
    }
    return <>{children}</>;
  }

  if (userInfo && userInfo.isFirst) {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'https://go.accziom.com';
      return <SpinnerScreen />;
    }
    return <Navigate to="/" />;
  }

  if (loadingStatus !== LoadingStatus.COMPLETED) return <SpinnerScreen />;

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  isFree: PropTypes.bool
};

export default AuthGuard;
