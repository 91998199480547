import axios from 'axios';
import * as querystring from 'querystring';
import { addCallApiCount } from 'src/slices/user';
import gstore from 'src/store';

const callHttpApi = async (url: string, mode: string, params: any) => {
  gstore.dispatch(addCallApiCount());
  const fullUrl = `${url}?${querystring.stringify({ mode, ...params })}`;
  const res = await axios.get(fullUrl);
  return res.data;
};

export default callHttpApi;
