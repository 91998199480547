import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/layout/LoadingScreen';
import AuthGuard from 'src/components/layout/AuthGuard';
import GuestGuard from 'src/components/layout/GuestGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const Dashboard = Loadable(lazy(() => import('src/pages/Dashboard')));
const Chat = Loadable(lazy(() => import('src/pages/chat')));
const ErrorPage = Loadable(lazy(() => import('src/pages/NotFound')));

const routes: RouteObject[] = [
  {
    path: '/public',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/public',
        element: <Chat />
      },
      {
        path: '/public/:threadKey',
        element: <Chat />
      }
    ]
  },
  {
    path: '/mine',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/mine',
        element: <Chat />
      },
      {
        path: '/mine/:threadKey',
        element: <Chat />
      }
    ]
  },
  {
    path: '/home',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/home',
        element: <Dashboard />
      }
    ]
  },
  {
    path: '/error',
    element: <ErrorPage />
  },
  {
    path: '/',
    children: [
      {
        path: '/',
        element: (
          <AuthGuard isFree>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Dashboard />
          }
        ]
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [{
      path: '/*',
      element: <ErrorPage />
    }]
  }
];

export default routes;
