import { combineReducers } from '@reduxjs/toolkit';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as organizationReducer } from 'src/slices/organization';
import { reducer as networkReducer } from 'src/slices/network';
import { reducer as tagReducer } from 'src/slices/tag';
import { reducer as taxonomyReducer } from 'src/slices/taxonomy';

const rootReducer = combineReducers({
  chat: chatReducer,
  organization: organizationReducer,
  user: userReducer,
  network: networkReducer,
  tag: tagReducer,
  taxonomy: taxonomyReducer
});

export default rootReducer;
