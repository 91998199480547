import type { FC } from 'react';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

interface NavItemProps {
  sourcePath: string;
  altName: string;
  height?: number;
  href?: string;
}

const NavItem: FC<NavItemProps> = ({ sourcePath, altName, height, href }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>('normal');

  return (
    <Box
      // height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onMouseEnter={() => setStatus('hover')}
      onMouseLeave={() => setStatus('normal')}
      onMouseDown={() => setStatus('down')}
      onMouseUp={() => {
        if (status !== 'down') return;
        setStatus('normal');
        navigate(href);
      }}
      style={{
        cursor: 'pointer'
      }}
      sx={{
        pt: status === 'down' ? '9px' : '8px',
        pb: status === 'down' ? '7px' : '8px',
        pl: status === 'down' ? '9px' : '8px',
        pr: status === 'down' ? '7px' : '8px',
        borderRadius: 1,
        position: 'relative',
        transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '::after': {
          content: '""',
          width: '100%',
          height: '100%',
          backgroundColor: status === 'down' || status === 'hover' ? '#00000020' : '#00000000',
          position: 'absolute',
          left: '0px',
          top: '0px',
          zIndex: '-1',
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          borderRadius: 1
        },
        ...((status === 'down' || status === 'hover') && { boxShadow: '0px 1px 2px #0a164626' })
      }}
    >
      <img
        src={sourcePath}
        alt={`${altName}`}
        height={height}
        style={{
          marginTop: '3px'
        }}
      />
    </Box>
  );
};

NavItem.propTypes = {
  sourcePath: PropTypes.string,
  href: PropTypes.string,
  height: PropTypes.number,
  altName: PropTypes.string
};

export default NavItem;
