const getUserDisplayName = (body: any): string => {
  if (body) {
    if (body.penName) return body.penName;
    if (body.displayName) return body.displayName;
    const formalName = [body.firstName, body.middleName, body.lastName].filter((_) => !!_).map((_) => _.trim()).join(' ');
    if (formalName) return formalName;
  }
  return 'Unknown';
};

export default getUserDisplayName;
