import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Tag } from '../../../Common/Model/tag';

interface TagState {
  tags: Tag[];
}

const initialState: TagState = {
  tags: []
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    clearState(state: TagState): void {
      state.tags = [];
    },
    addTags(state: TagState, action: PayloadAction<Tag[]>) {
      const newTags = action.payload;
      state.tags = [...state.tags, ...newTags.filter((tag) => !state.tags.some((ot) => ot.id === tag.id))];
    },
    updateTags(state: TagState, action: PayloadAction<{ newTags: Tag[]; removedIds: string[]; }>) {
      const { newTags, removedIds } = action.payload;
      state.tags = [...state.tags.filter((tag) => removedIds.includes(tag.id)), ...newTags.filter((tag) => !state.tags.some((ot) => ot.id === tag.id))];
    }
  }
});

export const { reducer } = slice;

export const clearTagState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const addTags = (tags: Tag[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.addTags(tags));
};

export const updateTags = (newTags: Tag[], removedIds: string[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.updateTags({ newTags, removedIds }));
};

export default slice;
