import * as LAMBDA_FN from 'src/../../Backend/constants.json';
import { lambdaRun } from './lambdaCall';

export const lambdaPutUserInfo = (_uid: string, _body: any) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'PUT', uid: _uid, body: _body })
);

export const lambdaPutUserInfoForFillup = (_uid: string, _body: any, email: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'PUT_FOR_FILLUP', uid: _uid, body: _body, email })
);

export const lambdaPutAvatar = (_uid: string, image: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'PUT_AVATAR', uid: _uid, body: image })
);

export const lambdaGetUserInfo = (_uid: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET', uid: _uid })
);

export const lambdaGetUserInfoByEmail = (_email: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET_BY_EMAIL', email: _email })
);

export const lambdaGetUserInfoByMemberId = (mid: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET_BY_MEMBER_ID', mid })
);

export const lambdaSearchUserByField = (_query: string, _fieldName: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'SEARCH_BY_FIELD', query: _query, fieldName: _fieldName })
);

export const lambdaGetTwilioToken = (_uid: string, notAdd?: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TWILIO_REQUEST_TOKEN, { method: 'GET_COMMUNITY_TOKEN', uid: _uid }, notAdd)
);

export const lambdaCreateCommunityAdminParticipants = (_sid: string, _uids: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TWILIO_REQUEST_TOKEN, { method: 'CREATE_COMMUNITY_ADMIN_PARTICIPANTS', sid: _sid, uids: _uids })
);

export const lambdaSendRawFeedback = (emailParams: any, emailTransportAttachments: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FEEDBACK_DISPATCH, { method: 'SEND_RAW', emailParams, emailTransportAttachments })
);

export const lambdaFindAddress = (_query: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ADDRESS_HELPER, { query: _query })
);

export const lambdaAcceptInvitations = (mids: string[], uid: string = null, isCreateOrg: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACCEPT_INVITATIONS', mids, uid, isCreateOrg })
);

export const lambdaJoinAsClient = (userInfo: any, domain: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'JOIN_AS_CLIENT', userInfo, domain })
);

export const lambdaAcceptInvitationOnSingin = (organizationId: string, email: string, asMember: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACCEPT_INVITATION_ON_SIGN_IN', organizationId, email, asMember })
);

export const lambdaAcceptOrgInvitationOnSingin = (organizationId: string, email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACCEPT_ORG_INVITATION_ON_SIGN_IN', organizationId, email })
);

export const lambdaGetPendingInvitations = (email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_PENDING_INVITATIONS', email })
);

export const lambdaCreateOrganization = (org: any, adminRole: any, userInfo: any, isFirst: boolean, teamNames: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CREATE_ORGANIZATION', org, adminRole, userInfo, isFirst, teamNames })
);

export const lambdaSetOrganizationInfo = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'PUT', body: _body })
);

export const lambdaGetUserMinInfos = (_users: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_USERS_MIN', users: _users })
);

export const lambdaSearchOrganizationByField = (_query: string, _fieldName: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'SEARCH_BY_FIELD', query: _query, fieldName: _fieldName })
);

export const lambdaGetOrgActivatedMembersOfUser = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ACTIVATED', uid: _uid })
);

export const lambdaCheckRegisterInvite = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CHECK_REGISTER_INVITE', uid: _uid })
);

export const lambdaGetOrganizationInfo = (_orgId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ORG_INFO', orgId: _orgId })
);

export const lambdaGetMembersFromOrgs = (_oids: string[]) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_MEMBERS', oids: _oids })
);

export const lambdaUpdateOrganizationInfo = (_orgId: string, _body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE', orgId: _orgId, body: _body })
);

export const lambdaCheckDomainNameAvailable = (dn: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CHECK_AVAILABILITY', domainName: dn })
);

export const lambdaCheckEmailAvailable = (email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CHECK_EMAIL_AVAILABILITY', email })
);

export const lambdaGetOrgMembersOfUser = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET', uid: _uid })
);

export const lambdaAddOrgMember = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_MEMBER', body: _body })
);

export const lambdaAddAndDeleteMembers = (newMembers: any[], deleteIds: string[], orgId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_DELETE_MEMBERS', newMembers, deleteIds, orgId })
);

export const lambdaDeleteOrgMember = (_mid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'DELETE_MEMBER', mid: _mid })
);

export const lambdaUpdateOrgMember = (_mid: string, _body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_MEMBER', mid: _mid, body: _body })
);

export const lambdaUpdateOrgMemberWithEmail = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_MEMBER_WITH_EMAIL', body: _body })
);

export const lambdaAddOrgMemberRole = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_MEMBER_ROLE', body: _body })
);

export const lambdaUpdateOrgMemberRole = (_rid: string, _body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_MEMBER_ROLE', rid: _rid, body: _body })
);

export const lambdaDeleteOrgMemberRole = (_oid: string, _rid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'DELETE_MEMBER_ROLE', oid: _oid, rid: _rid })
);

export const lambdaAddOrgTeam = (_teamInfo: any, _memberIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_TEAM', teamInfo: _teamInfo, memberIds: _memberIds })
);

export const lambdaUpdateOrgTeam = (_tid: string, _teamInfo: any, _addMemberIds: string[], _deleteMemberIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_TEAM', tid: _tid, teamInfo: _teamInfo, addMemberIds: _addMemberIds, deleteMemberIds: _deleteMemberIds })
);

export const lambdaGetOrgStructure = (_oid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ORG', oid: _oid })
);

export const lambdaGetOrgTeamMembers = (_tid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_TEAM_MEMBER', tid: _tid })
);

export const lambdaDeleteOrgTeam = (_tid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'DELETE_TEAM', tid: _tid })
);

export const lambdaGetOrgBannerUploadUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_UP_BANNER_URL', id })
);

export const lambdaGetOrgBannerUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_BANNER_URL', id })
);

export const lambdaCreateAnzicTaxonomyInfoByType = (category: any, type: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'CREATE_ANZIC_BY_TYPE', category, type })
);

export const lambdaGetAnzicTaxonomyInfo = (): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'GET_ANZIC' })
);

export const lambdaGetAnzicTaxonomyInfoByType = (type: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'GET_ANZIC_BY_TYPE', type })
);

export const lambdaGetAnzicTaxonomyInfoByCodeAndType = (code: string, type: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'GET_ANZIC_BY_CODE_TYPE', code, type })
);

export const lambdaUpdateTags = (newTags: any[], removedIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'UPDATE_SIMPLE', newTags, removedIds })
);

export const lambdaCreateOrUpdateTags = (objectId: string, objectType: number, names: string[], removedIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'CREATE_OR_UPDATE', objectId, objectType, names, removedIds })
);

export const lambdaDuplicateTags = (objectId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'DUPLICATE', objectId })
);

export const lambdaGetTags = (objectId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'GET', objectId })
);

export const lambdaGetMultiObjectTags = (ids: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'GET_MULTI', ids })
);

export const lambdaCreatePurchaseHistory = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASEHISTORY_DISPATCH, { method: 'CREATE', body })
);

export const lambdaSendMsgToPhone = (phoneNumber: string, msg: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_SNS_DISPATCH, { method: 'SNS_SEND', phoneNumber, msg })
);

export const lambdaGetAttachmentFileUploadUrl = (uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_ATTACHMENT_DISPATCH, { method: 'GET_UP_URL', uid })
);

export const lambdaGetAttachmentFileDownloadUrl = (_uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_ATTACHMENT_DISPATCH, { method: 'GET_URL', uid: _uid })
);

export const lambdaDeleteAttachmentFile = (uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_ATTACHMENT_DISPATCH, { method: 'DELETE_FILE', uid })
);

export const lambdaRemoveChatFromInvitation = (convId: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'REMOVE_CHAT', convId })
);

export const lambdaSearchUserWithFriend = (query: string, showUser: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'SEARCH_USERS_BY_QUERY', query, showUser })
);

export const lambdaAddAndDeleteMessages = (sid: string, newMsgs: any[], deleteIds: number[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'ADD_DELETE_MESSAGES', sid, newMsgs, deleteIds })
);

export const lambdaUpdateMessage = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'UPDATE_MESSAGE', body })
);

export const lambdaPublishChannel = (sid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'PUBLISH_CHANNEL', sid })
);

export const lambdaJoinChannel = (sid: string, recipients: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'JOIN_PARTICIPANTS', sid, recipients })
);

export const lambdaCreateChannel = (info: any, recipients: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'ADD_CHANNEL', info, recipients })
);

export const lambdaUpdateChannel = (sid: string, body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'UPDATE_CHANNEL', sid, body })
);

export const lambdaTerminateChannel = (sid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'TERMINATE_CHANNEL', sid })
);

export const lambdaLeaveChannel = (sid: string, recipient: any): Promise<string[]> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'LEAVE_CHANNEL', sid, recipient })
);

export const lambdaAddAndDeleteChannelParticipants = (sid: string, newRecipients: any[], deleteIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'ADD_DELETE_PARTICIPANTS', sid, newRecipients, deleteIds })
);

export const lambdaActivateParticipants = (sid: string, recipientIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'ACTIVATE_PARTICIPANTS', sid, recipientIds })
);

export const lambdaSearchChannelMessage = (sid: string, query: string, offset: number, limit: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CHANNEL_DISPATCH, { method: 'SEARCH_MESSAGE', sid, query, offset, limit })
);

export const lambdaCreateFileResources = (resources: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILERESOURCE_DISPATCH, { method: 'PUT', resources })
);

export const lambdaGetFileResourcesByItemId = (itemId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILERESOURCE_DISPATCH, { method: 'GET', itemId })
);

export const lambdaUpdateFileResources = (add: any[], remove: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILERESOURCE_DISPATCH, { method: 'UPDATE', add, remove })
);

export const lambdaGetInvitationRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'GET_BY_RELATED', userId })
);
