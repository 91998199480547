import type { FC, ReactNode } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import { NAVBAR_HEIGHT } from 'src/globals';
import useAuth from 'src/hooks/useAuth';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

const AboutDashboardLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  height: '100%',
  width: '100%'
});

const DashboardLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: NAVBAR_HEIGHT,
  height: '100%',
  width: '100%'
});

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  width: '100%'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  width: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const isMyAbout = auth.isAuthenticated && !!searchParams.get('aboutus');

  return (
    <DashboardLayoutRoot>
      {isMyAbout ? (
        <AboutDashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <Outlet />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </AboutDashboardLayoutWrapper>
      ) : (
        <>
          <DashboardNavbar />
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <Outlet />
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </>
      )}
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
